<template>
  <ValidationObserver ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="onClose"
      :loading="isLoading"
      @submit="handleSubmit(onSubmit)"
      submit="Save"
      :close-button="isViewMode"
      button-class="bg-dynamicBackBtn text-white rounded"
      :disabled-button="isLoading"
      width="width:520px"
    >
      <template v-slot:title>
        <h1 class="text-darkPurple font-bold text-lg">Leave Category</h1>
      </template>
      <template v-slot:default>
        <div class="flex flex-col justify-start gap-8">
          <div class="flex flex-col gap-5 border-b border-dashed border-romanSilver pb-5">
            <div class="flex flex-col justify-start gap-2">
              <p class="text-sm text-darkPurple">Category Name</p>
              <div class="h-10 flex items-center px-5 bg-cultured border border-romanSilver rounded">
                <p class="text-base text-darkPurple">{{ activeTabItem.name }}</p>
              </div>
            </div>
            <div v-if="!hasSpecificModule('orgAndPeople') && $subscriptionPlan">
              <div class="flex flex-col justify-start gap-2">
                <p class="text-sm text-darkPurple">Job Level (s)</p>
                <div class="h-10 flex items-center px-5 bg-cultured border border-romanSilver rounded">
                  <p class="text-base text-darkPurple">All Levels Selected</p>
                </div>
              </div>
            </div>
            <validation-provider v-else class="w-full" name="Job Level (s)" :rules="{ required: true }" v-slot="{ errors }">
              <label class="date-label">Job Level (s)</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="isViewMode"
                :reduce="level => level.id"
                :options="levelOptions"
                :close-on-select="false"
                v-model="setting.level"
                @input="onSelectedLevels"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!setting.level.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
              <small class="text-desire">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="flex flex-col justify-start gap-5">
            <h2 class="font-bold text-lg text-darkPurple">Leave Settings</h2>
            <div class="flex flex-col justify-start gap-3">
              <h3 class="text-base font-semibold text-darkPurple">Leave Status:</h3>
              <c-radio
                class="c-radio"
                space-between="mr-2 ml-2"
                :rules="['required']"
                :disabled="isViewMode"
                v-model="setting.isEnabled"
                :options="[
                  { name: 'Enabled', value: 'true' },
                  { name: 'Disabled', value: 'false'},
                ]"
                />
            </div>
            <div class="flex flex-col justify-start gap-3 w-4/6">
              <div class="flex flex-col justify-start gap-0">
                <h3 class="text-base font-semibold text-darkPurple">Delegation of Authority:</h3>
                <p class="text-xs text-romanSilver">Is Delegation of authority mandatory for this leave type?</p>
              </div>
              <c-radio
                class="c-radio"
                space-between="mr-2 ml-2"
                :rules="['required']"
                v-model="setting.hasDelegation"
                :disabled="isViewMode"
                :options="[
                  { name: 'Yes', value: 'true' },
                  { name: 'No', value: 'false'},
                ]"
              />
            </div>
            <div class="flex flex-col justify-start gap-3">
              <div class="flex flex-col justify-start gap-0">
                <h3 class="text-base font-semibold text-darkPurple">Eligible for allowance:</h3>
                <p class="text-xs text-romanSilver">Is this leave type eligible for leave allowance?</p>
              </div>
              <c-radio
                class="c-radio"
                space-between="mr-2 ml-2"
                v-model="setting.eligibility"
                :options="[
                  { name: 'Yes', value: 'true' },
                  { name: 'No', value: 'false' },
                ]"
              />
              <c-select
                v-if="setting.eligibility === 'true' && paySettingOptions.length > 0"
                label="Select Allowance"
                :options="paySettingOptions"
                v-model="setting.paySettingId"
                :disabled="isViewMode"
              />
              <div v-if="setting.eligibility === 'true' && paySettingOptions.length === 0"
                class="w-full px-5 py-2 flex gap-2 rounded-md bg-yellow-50 border border-carrotOrange"
              >
                  <Icon icon-name="icon-info" size="xs" class="text-carrotOrange" />
                  <span class="text-jet w-full rounded-md text-left text-xs">
                    You don't have any leave pay item to allow this
                  </span>
                </div>
            </div>
            <div class="flex flex-col justify-start gap-3">
              <div class="flex flex-col justify-start gap-0">
                <h3 class="text-base font-semibold text-darkPurple">Total Leave Entitlement (Days):</h3>
                <p class="text-xs text-romanSilver">Set the maximum applicable number of days. <span class="text-base font-bold text-razzmataz">*</span></p>
              </div>
              <div class="flex justify-start items-center gap-2">
                <c-text
                  class="w-28 applicable-days"
                  placeholder="0" type="number"
                  min="0"
                  v-model="setting.maximumApplicableDays"
                  :disabled="isViewMode"
                />
                <div class="w-28 h-10 flex justify-center items-center px-5 bg-cultured border border-romanSilver rounded">
                  <p class="text-base text-darkPurple font-semibold">Days</p>
                </div>
              </div>
            </div>
            <template v-if="activeTabItem.id === '1'">
              <div class="flex flex-col justify-start gap-3">
                <div class="flex flex-col justify-start gap-0">
                  <h3 class="text-base font-semibold text-darkPurple">Leave Carry Forward Policy</h3>
                  <p class="text-xs text-romanSilver">How many days of leave can be carried forward?</p>
                </div>
                <div class="flex justify-start items-center gap-2">
                  <c-text
                    class="w-28 applicable-days"
                    placeholder="0" type="number"
                    min="0"
                    :rules="['required']"
                    v-model="setting.carryForwardLimit"
                    :disabled="isViewMode"
                  />
                  <div class="w-28 h-10 flex justify-center items-center px-5 bg-cultured border border-romanSilver rounded">
                    <p class="text-base text-darkPurple font-semibold">Days</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-3">
                <div class="flex flex-col justify-start gap-0">
                  <h3 class="text-base font-semibold text-darkPurple">Carry Forward Leave Expiry</h3>
                  <p class="text-xs text-romanSilver">How long does an employee have to use up carried over annual leave before they expire?</p>
                </div>
                <div class="flex justify-start items-center gap-2">
                  <c-text
                    class="w-28 applicable-days"
                    placeholder="0" type="number"
                    min="0"
                    :rules="['required']"
                    v-model="setting.carryForwardExpiry"
                    :disabled="isViewMode"
                  />
                  <div class="w-28 h-10 flex justify-center items-center bg-cultured border-romanSilver rounded">
                    <c-select
                      style="margin-top: -8px;border:0;width:100%"
                      v-model="setting.carryForwardExpiryType"
                      :disabled="isViewMode"
                      placeholder="Select"
                      :options="[
                        { name: 'Days', id: 'days'},
                        { name: 'Weeks', id: 'week'},
                        { name: 'Months', id: 'months'}
                      ]"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CRadio from "@scelloo/cloudenly-ui/src/components/radio"
import CText from "@scelloo/cloudenly-ui/src/components/text"
import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox"
import RightSideBar from "@/components/RightSideBar"
import Icon from "@/components/Icon"

export default {
components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    CSelect,
    CRadio,
    CText,
    CCheckbox,
    RightSideBar,
    Icon
  },
  data() {
    return {
      isOpen: false,
      isLoading: true,
      isViewMode: false,
      setEligible: '',
      activeTabItem: null,
      levelOptions: [],
      paySettingOptions: [],
      leaveLevels: [],
      allOption: [],

      setting: {
        level: [],
        status: null,
        eligibility: null,
        isEnabled: null,
        hasDelegation: null,
        paySettingId: null,
        maximumApplicableDays: null,
        carryForwardLimit: null,
        carryForwardExpiry: null,
        carryForwardExpiryType: null
      }
    };
  },
  methods: {
    async onOpen({ setting = {}, activeTabItem }, actionType){
      this.isLoading = true
      this.isOpen = true
      this.activeTabItem = activeTabItem
      if (actionType === 'view') this.isViewMode = true
      if (actionType === 'edit') this.isViewMode = false

      if (Object.values(setting).length) {
        this.setting = {
          ...setting,
          level: [...setting.level],
          isEnabled: JSON.stringify(setting.isEnabled),
          hasDelegation: JSON.stringify(setting.hasDelegation),
        }
        if (setting.isAll) {
          this.levelOptions = [{ name: 'All Level Selected', id: 'all' }]
          this.setting.level = [{ name: 'All Level Selected', id: 'all' }]
        }
      }

      await this.geOrgtLevels()
      await this.getOrgPaySetting()

      this.isLoading = false
    },
    onSubmit(){
      this.isLoading = true

      if (!this.setting.paySettingId) {
        this.setting.status = false;
        this.setting.eligibility = false;
      }

      const payload = {
        ...this.setting,
        orgId: this.$AuthUser.orgId,
        updatedBy: this.$AuthUser.id,
        categoryId: this.activeTabItem.id,
        eligibility: this.setting.eligibility === 'true',
        isEnabled: this.setting.isEnabled === 'true',
        hasDelegation: this.setting.hasDelegation === 'true',
        levelIds: [],
      }

      if (this.setting.level?.some(v => v.id === 'all')) {
        payload.levelIds = this.leaveLevels.filter(i => i)

        this.$_updateLevelSettings(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.$emit('success', data)
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 3000 })
          this.$emit('error', error)
          this.isLoading = false
        })
      } else {
        payload.levelIds = this.leaveLevels.filter(item => (
          this.setting.level.includes(item.id)
        ))
        if (!payload.levelIds.length) {
          payload.levelIds = this.setting.level
        }

        this.$_updateLevelSettings(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.$emit('success', data)
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 3000 })
          this.$emit('error', error)
          this.isLoading = false
        })
      }
    },
    onSelectedLevels(items){
      if (items.includes('all')) {
        this.levelOptions = [{ name: 'All Level Selected', id: 'all' }]
        this.setting.level = [{ name: 'All Level Selected', id: 'all' }]
      } else if (items.length === this.levelOptions.length - 1) {
        this.levelOptions = [...this.allOption];
        this.setting.level = items.filter(item => item)
      } else {
        this.levelOptions = [...this.allOption];
      }
    },
    onClose(){
      this.isLoading = false
      this.isOpen = false
      this.isViewMode = false
      this.activeTabItem = ''
      Object.assign(this.$data.setting, this.$options.data().setting)
      this.$emit('close')
    },
    async geOrgtLevels() {
      await this.$_getLevels().then(({ data }) => {
        this.leaveLevels = data.levels;
        this.allOption = [{ name: 'All', id: 'all' }, ...data.levels]
        this.levelOptions = [...this.allOption]
      })
    },
    async getOrgPaySetting() {
      await this.$_getLeavePayItems().then(({ data }) => {
        this.paySettingOptions = data.paySettings.map(item => ({
          ...item, name: item.nameOnPayslip,
        }))
      })
    },
  }
}
</script>
<style scoped>
.c-radio {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 20px !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .applicable-days label {
  display: none !important;
}
</style>